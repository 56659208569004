import { debounce } from "./debounce.js";

function matchHeight() {
  let matchTheHeight = debounce(function () {
    matchHeight = (match) => {
      const getDivs = document.querySelectorAll("." + match);
      const heightsArray = [];

      // for each div
      getDivs.forEach((x, i) => heightsArray.push(getDivs[i].offsetHeight));
      const getHighest = () => Math.max(...heightsArray);

      // get highest height function
      const tallest = getHighest();
      getDivs.forEach((x, i) => (getDivs[i].style.height = tallest + "px"));
    };

    // get all the match height id's
    const matchHeights = document.querySelectorAll("#match-height");

    // take the first class defined and then run the function on that
    matchHeights.forEach((match) => {
      const oneMatchHeight = match.className.split(" ")[0];
      matchHeight(oneMatchHeight);
    });
  }, 250);

  // match the height on load
  matchTheHeight();

  window.addEventListener("resize", (event) => {
    setTimeout(function () {
      matchTheHeight();
    }, 200);
  });
}

export { matchHeight };
