import { debounce } from "./modules/debounce.js";
import { mobileNav } from "./modules/mobile-nav.js";
import { matchHeight } from "./modules/match-height.js";
import { bgVideo } from "./modules/bg-video.js";
import { searchToggle } from "./modules/search-toggle.js";
import { projectSlider } from "./modules/project-slider.js";
import { filter } from "./modules/filter.js";
import { autoBg } from "./modules/auto-bg.js";
import { flickitySlider } from "./modules/flickity-slider.js";
import { tableSwitcher } from "./modules/table-switcher.js";
import { hideElements } from "./modules/hide-elements.js";

function init() {
  debounce();
  mobileNav();
  matchHeight();
  bgVideo();
  searchToggle();
  projectSlider();
  filter();
  autoBg();
  flickitySlider();
  tableSwitcher();
  hideElements();
}

window.addEventListener(
  "load",
  (event) => {
    init();
  },
  false,
);
