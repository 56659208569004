// no slide
function projectSlider() {
  if (document.querySelector("#js-project-slider") !== null) {
    const projectSlider = document.querySelector("#js-project-slider");
    const projectSliderDesc = document.querySelector("#js-project-slider-desc");
    const slideLeft = document.querySelector("#js-slide-left");
    const slideRight = document.querySelector("#js-slide-right");
    const containingDivs = projectSlider.getElementsByTagName("div");

    if (document.querySelectorAll("#js-project-slider > div").length > 2) {
      slideRight.addEventListener("click", () => {
        projectSlider.appendChild(containingDivs[0]);
        projectSliderDesc.appendChild(projectSliderDesc.children[0]);
      });

      slideLeft.addEventListener("click", () => {
        projectSlider.insertBefore(
          containingDivs[containingDivs.length - 2],
          containingDivs[0],
        );
        projectSliderDesc.insertBefore(
          projectSliderDesc.children[projectSliderDesc.children.length - 1],
          projectSliderDesc.children[0],
        );
      });
    } else {
      document.querySelector("#js-slide-left").style.display = "none";
      document.querySelector("#js-slide-right").style.display = "none";
    }
  }
}

export { projectSlider };
