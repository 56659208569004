function filter() {
  if (document.querySelector("#js-filter-show") !== null) {
    const filterShow = document.querySelector("#js-filter-show");
    const filter = document.querySelector("#js-filter-mobile");

    filter.style.display = "none";

    filterShow.addEventListener("click", (e) => {
      e.preventDefault();
      if (
        filter.style.display === "none" &&
        filterShow.innerHTML === "Filter by"
      ) {
        filter.style.display = "flex";
        filterShow.innerHTML = "Close";
        filterShow.classList.remove("--down-return");
        filterShow.classList.add("--menu-x-white");
      } else {
        filter.style.display = "none";
        filterShow.innerHTML = "Filter by";
        filterShow.classList.remove("--menu-x-white");
        filterShow.classList.add("--down-return");
      }
    });
  }

  if (document.querySelector("#js-filter-show-desktop") !== null) {
    const filterShows = document.querySelectorAll("#js-filter-show-desktop");
    const filters = document.querySelector("#js-filters");

    filters.style.display = "none";

    filterShows.forEach((filterShow) => {
      filterShow.addEventListener("click", (e) => {
        e.preventDefault();
        if (
          filters.style.display === "none" &&
          filterShow.innerHTML === "Filter by"
        ) {
          filters.style.display = "flex";
          filterShow.innerHTML = "Close";
          filterShow.classList.remove("--down-return-black");
          filterShow.classList.add("--filter-x");
        } else {
          filters.style.display = "none";
          filterShow.innerHTML = "Filter by";
          filterShow.classList.remove("--filter-x");
          filterShow.classList.add("--down-return-black");
        }
      });
    });
  }
}

export { filter };
