const hideElements = () => {
  window.onload = () => {
    const searchResult = document.querySelector("#js-search-result");
    const searchToggle = document.querySelector("#js-search-toggle");
    if (window.location.href.indexOf("search?q") > -1) {
      if (searchResult) {
        searchToggle.style.display = "none";
      }
    }
  };
};

export { hideElements };
