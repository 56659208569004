function bgVideo() {
  const video = document.querySelector("#mount-stuart-video");
  const videoVimeo = document.querySelector(".mount-vimeo");
  const introAnimation = document.querySelector(".intro-animation");

  // if (video) {
  //   window.setTimeout(() => {
  //     video.classList.add("db", "fade-in");
  //     video.play()
  //     introAnimation.classList.add("fade-out");
  //   }, 4000);
  // }

  if (videoVimeo) {
    window.setTimeout(() => {
      videoVimeo.classList.add("db", "fade-in");
      // video.play()
      introAnimation.classList.add("fade-out");
    }, 4000);
  }
}

export { bgVideo };
