function tableSwitcher() {
  const tables = ["house", "gardens", "courtyard", "visitorcentre"];

  function showTable(targetTable) {
    for (var i = 0; i < tables.length; i++) {
      document.getElementById("times-" + tables[i]).classList.add("dn");
      document.getElementById("header-" + tables[i]).classList.add("black");
    }
    document.getElementById("times-" + targetTable).classList.remove("dn");
    document.getElementById("header-" + targetTable).classList.remove("black");
    document.getElementById("header-" + targetTable).classList.add("black-40");
  }
}

export { tableSwitcher };
