const mobileNav = () => {
  const menuOpen = document.querySelector("div.menu-open");
  const menuClose = document.querySelector("div.menu-close");

  menuOpen.addEventListener("click", () =>
    document.querySelector("body").classList.add("open"),
  );

  menuClose.addEventListener("click", () =>
    document.querySelector("body").classList.remove("open"),
  );

  // desktop nav
  const desktopOpen = document.querySelector(".desktop-open");
  const mobileMenu = document.querySelector("#js-fadenav");

  desktopOpen.addEventListener("click", () => {
    mobileMenu.style.display == "block"
      ? (mobileMenu.style.display = "none")
      : (mobileMenu.style.display = "block");
  });

  // dropdown nav
  const menuDropdown = document.querySelector("#js-mobile-dropdown");
  const dropdownShow = document.querySelector("#child-nav");

  menuDropdown.addEventListener("click", (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (dropdownShow.style.display == "block") {
      dropdownShow.style.display = "none";
      dropdownShow.style.opacity = "0";
    } else {
      dropdownShow.style.display = "block";
      dropdownShow.style.opacity = "1";
    }
  });
};

export { mobileNav };
