function autoBg() {
  setTimeout(() => {
    if (document.querySelector("#js-projects-two") !== null) {
      const autoBg = document.querySelector("#js-auto-bg").clientHeight;
      const hrHeight = document.querySelector("#js-auto-bg-hr");
      hrHeight.style.height = autoBg / 2 + "px";
    }
  }, 1000);
}

export { autoBg };
