import Flickity from "flickity";

function flickitySlider() {
  const elem = document.querySelector(".main-carousel");
  if (elem) {
    const flkty = new Flickity(elem, {
      // options
      cellAlign: "center",
      prevNextButtons: true,
      wrapAround: true,
      pageDots: false,
      arrowShape: {
        x0: 20,
        x1: 65,
        y1: 45,
        x2: 70,
        y2: 40,
        x3: 30,
      },
    });
  }

  // Project Picker
  setTimeout(() => {
    const car = document.querySelector(".project-carousel");
    if (car) {
      const flkty = new Flickity(car, {
        // options
        groupCells: true,
        pageDots: false,
        arrowShape: {
          x0: 15,
          x1: 60,
          y1: 40,
          x2: 60,
          y2: 35,
          x3: 20,
        },
      });
    }
  }, 500);
}

export { flickitySlider };
